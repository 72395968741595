section.featured-events {
  padding: 110px 20px 160px;
  background-image: url('/themes/athena/assets/dist/images/featured-events.jpg');
  background-size: cover;
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: rgba(255, 255, 255, 0.7);
  }

  h2 {
    margin: 0 0 20px;
    color: $events-featured-heading;
    font-weight: $regular;
    font-style: normal;
    font-size: 36px;
    @include medium-up {
      max-width: 1600px;
      margin: 0 auto 30px;
    }
  }

  .events {
    width: 100%;
    display: block;
    @include medium-up {
      display: flex;
      max-width: 1600px;
      margin: 0 auto;
    }

    .event {
      position: relative;
      display: inline-block;
      overflow: hidden;
      width: 100%;
      vertical-align: top;
      background: white;
      margin: 15px 0;
      text-align: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, .15);

      // @include medium-up {
      //   width: 33%;
      //   margin: 0 15px;
      // }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .event-image {
        width: 100%;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 0;
          background: rgba(0, 0, 0, 0.7);
        }
      }

      .event-info {
        padding: 30px;

        .event-wrap {
          position: absolute;
          top: 30%;
          text-align: center;
          width: 100%;
          left: 0;
          padding: 0 20px;

          h3 {
            margin: 0 0 10px;
            font-size: 30px;
            font-weight: $bold;

            a {
              color: $events-title-color;
              text-decoration: none;
              line-height: 24px;
              border: none;

              &:hover,
              &:focus {
                color: white;
              }
            }
          }

          .event-date {
            font-size: 14px;
            font-weight: $bold;
            color: $events-date-color;
            letter-spacing: 1px;
          }
        }

        .event-blurb {
          font-size: 18px;
          line-height: 24px;
          font-weight: $regular;
          color: $events-blurb-color;
          margin-top: 0;
        }

        .event-read-more {
          a {
            color: $primary-color;
            text-decoration: none;
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 1px;

            &:hover,
            &:focus {
              color: $tertiary-color;
            }
          }
        }
      }
    }
  }

  .view-all-events {
    padding: 10px 0 0;
    max-width: 1600px;
    margin: auto;

    a {
      color: $events-view-all-color;
      text-decoration: none;
      font-weight: $regular;
      font-size: 24px;
      border: none;

      &:hover,
      &:focus {
        color: white;
      }
    }
  }

  .owl-nav {
    .owl-prev,
    .owl-next {
      &:before {
        color: $events-slider-buttons;
      }
    }
  }

  .owl-dots {
    .owl-dot {
      background: $events-slider-pagers;
      color: $events-slider-pagers;

      &.active {
        background: $events-slider-pagers;
        color: $events-slider-pagers;
      }
    }
  }
}

.sidebar-content {
  .featured-events {
    padding: 0 20px 20px;
    background: none;

    &:before {
      display: none;
    }

    h2 {
      color: black;
      font-size: 30px;
      font-weight: 700;
      border-bottom: 1px solid $events-featured-heading;
      padding-bottom: 15px;
      margin: 0 auto 15px;
      // margin-bottom: 15px;
    }

    .events {
      display: block;

      .event {
        text-align: left;
        box-shadow: none;

        h3 {
          margin-top: 0;
          margin-bottom: 10px;
        }

        .event-image {
          &:before {
            display: none;
          }
        }

        .event-info {
          padding: 30px 0;
        }

        .event-blurb {
          margin-top: 30px !important;
          margin-bottom: 15px;
        }

        .event-read-more {
          a {
            text-transform: none;
            letter-spacing: 0px;
          }
        }
      }
    }

    .view-all-events {
      a {
        padding: 15px 40px;
        margin-left: 0;
        background: white;
        border: 2px solid $form-submit-button-color;
        cursor: pointer;
        border-radius: 50px;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 2px;
        color: $form-submit-button-color;
        font-weight: 700;

        &:hover,
        &:focus {
          background: $form-submit-button-color;
          color: $form-submit-button-text-hover-color;
          text-decoration: none;
        }
      }
    }
  }
}