section.banner {
  position: relative;
  z-index: 0;
  @include medium-up {
    max-height: 720px;
    height: 80vh;
  }

  .slides {
    padding-bottom: 30px;
    background: black;

    .owl-stage-outer {
      padding-bottom: 40px;
      margin-bottom: -30px;
      @include medium-up {
        padding-bottom: 0;
      }

      .owl-item {
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  .owl-nav {
    padding: 0 20px;
    @include medium-up {
      position: absolute;
      top: 50%;
    }
  }

  .owl-dots {
    top: -10px;
  }

  .slide {
    // height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 160px;

    &:before {
      content: '';
      height: 100%;
      background: linear-gradient(0deg, #000000, #000000 30%, transparent 80%) no-repeat;
      position: absolute;
      width: 100%;
      @include medium-up {
        background: linear-gradient(0deg, #000000, #000000 10%, transparent 80%) no-repeat;
      }
    }
    @include medium-up {
      max-height: 720px;
      height: 80vh;
      padding: 0;
    }

    .slide-content {
      color: white;
      z-index: 1;
      position: relative;
      padding: 30px 20px;
      text-align: center;
      @include medium-up {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 1024px;
        padding: 0;
        text-align: left;
        padding: 0 80px;
      }

      .featured-image {
        @include medium-up {
          margin-right: 50px;
          max-width: 30%;
        }

        img {
          margin: 0 auto 50px;
          width: 100%;
          @include medium-up {
            margin-bottom: 0;
          }
        }

        &.hide {
          display: none;
        }
      }

      .caption {
        display: block;
        @include medium-up {
          max-width: 70%;
        }

        .title {
          margin-top: 0;
          margin-bottom: 10px;
          color: $color04;
          font-weight: 100;
          font-size: 36px;
          font-weight: $regular;
          font-style: normal;
          @include medium-up {
            font-size: 60px;
          }
        }

        .blurb {
          margin: auto;
          padding-bottom: 30px;
          width: 100%;
          font-size: 20px;
          font-weight: $light;
          line-height: 30px;
          @include medium-up {
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}

.cycle-slideshow {
  // padding-bottom: 80px;
  z-index: 0;
  @include medium-up {
    padding-bottom: 0;
  }
}

.cycle-pager {
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 999;
  bottom: 0;
  padding: 80px 0;
  @include medium-up {
    display: none;
  }

  span {
    margin: 0 7px;
    padding: 0 7px;
    background: $slider-pagers;
    color: $slider-pagers;
    text-decoration: none;
    font-size: 18px;
    border-radius: 50px;
    cursor: pointer;
    opacity: .4;

    &.cycle-pager-active {
      background: white;
      color: white;
      opacity: 1;
    }
  }
}

.prev-next-pager {
  position: absolute;
  z-index: 999;
  bottom: 0;
  padding: 45px 20px;
  display: flex;
  justify-content: space-between;
  max-width: 1150px;
  align-items: center;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0;
  @include medium-up {
    top: 50%;
    padding: 20px;
  }

  span {
    color: white;
    cursor: pointer;

    &.prev,
    &.next {
      &:before {
        font-family: 'Font Awesome 5 Free';
        font-size: 48px;
        color: $slider-buttons;
        opacity: .15;
        font-weight: 900;
        @include medium-up {
          font-size: 72px;
        }
      }

      &:hover,
      &:focus {
        &:before {
          opacity: 1;
        }
      }
    }

    &.prev {
      &:before {
        content: '\f053';
      }
    }

    &.next {
      &:before {
        content: '\f054';
      }
    }
  }
}