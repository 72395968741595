body.fixed {
  margin-top: 160px;
  overflow: hidden;
  @include medium-up {
    margin-top: 0;
    overflow: initial;
    position: relative;
  }
}

header {
  display: block;
  background: $header-background-color;
  @include medium-up {
    position: relative;
    z-index: 200;
    width: 100%;
  }

  &.fixed {
    position: fixed;
    z-index: 1;
    width: 100%;
    // overflow-y: scroll;
    overflow-y: auto;
    height: 100%;
    top: 0;
    background: transparent;
    @include medium-up {
      position: relative;
      height: auto;
      overflow: inherit;
    }

    .nav-primary {
      .ul1 {
        background-color: transparent;

        &:before {
          content: '';
          height: 100%;
          background-color: $mobile-subnav-background-color;
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          min-height: 100vh;
          @include medium-up {
            display: none;
          }
        }
      }
    }
  }
}

.nav-wrapper {
  position: relative;
  width: 100%;
  background: $header-background-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 20px 0 0;
  @include medium-up {
    padding: 25px 50px;
    min-height: 150px;
    height: 100%;
    flex-wrap: wrap;
  }

  .nav-primary {
    position: static;
    // background: $header-background-color;
    display: block;
    width: 100%;
    padding: 15px 20px;
    @include medium-up {
      position: relative;
      display: inline-flex;
      justify-content: flex-end;
      float: right;
      padding-right: 30px;
      z-index: 2;
      width: calc(100% - 200px);
      right: -30px;
    }

    .nav-toggle {
      position: relative;
      color: $primary-color;
      font-size: 30px;
      cursor: pointer;
      float: right;
      height: 35px;
      display: flex;
      align-items: center;
      @include medium-up {
        display: none;
      }

      .fa-times {
        display: none;
      }

      .fa-bars {
        display: block;
      }
    }

    ul {
      left: 0;
      display: none;
      width: 100%;
      margin: 0;
      position: absolute;
      padding: 50px 60px;
      top: 110px;
      list-style: none;
      background-color: $mobile-subnav-background-color;
      z-index: 1;
      @include medium-up {
        top: 50%;
        display: inline-block;
        margin: 0;
        padding: 0;
        left: -25px;
        width: auto;
        height: auto;
        background-color: transparent;
        list-style: none;
        position: relative;
        min-height: auto;
        z-index: 0;

        &.ul1 {
          display: inline-block !important;
        }
      }

      li {
        position: relative;
        margin: 0 0 40px;
        @include medium-up {
          margin: 0 auto;
          padding-bottom: 0;
          display: inline-block;
          margin: 0 5px;
          width: auto;
        }

        &:last-child {
          margin-right: 0;

          a {
            margin-right: 0;
          }

          ul {
            @include medium-up {
              right: 0px;
              left: auto;
            }
          }
        }

        &:before {
          content: none;
        }

        .ul2 {
          &.open {
            .li2 {
              span {
                display: none;
              }

              .ul3 {
                position: relative;
                padding: 10px 0 0 10px;
                top: 0;
                background: transparent;
                display: block;

                .li3 {
                  margin: 0;

                  a {
                    padding-bottom: 20px;
                    font-style: italic;
                    white-space: normal;
                    color: white;
                  }

                  span {
                    display: none;
                  }
                }
              }
            }
            @include medium-up {
              display: block !important;
              z-index: 1;

              .li2 {
                span.toggler {
                  display: none;
                }

                .ul3 {
                  left: 0;
                  padding: 0;

                  .li3 {
                    width: 100%;
                    margin: 0;

                    a {
                      font-size: 14px;
                      text-transform: none;
                      color: $mobile-subnav-color;
                      font-weight: 300;
                      padding-bottom: 6px;
                      font-style: normal;

                      &:hover {
                        color: white;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &:hover {
          cursor: pointer;

          > a {
            @include medium-up {
              color: $nav-item-hover-color;
            }
          }

          .ul2 {
            .ul3 {
              position: relative;
              padding: 10px 0 0 10px;
              top: 0;
              background: transparent;

              .li3 {
                margin: 0;

                a {
                  padding-bottom: 20px;
                  font-style: italic;
                  white-space: normal;
                }
              }
            }
            @include medium-up {
              display: block !important;
              z-index: 1;
              border-radius: 10px;
              border-top-left-radius: 0;

              li {
                span.toggler {
                  display: none;
                }

                .ul3 {
                  left: 0;
                  padding: 0;

                  .li3 {
                    width: 100%;
                    margin: 0;

                    a {
                      font-size: 14px;
                      text-transform: none;
                      color: $mobile-subnav-color;
                      font-weight: 300;
                      padding-bottom: 6px;
                      font-style: normal;

                      &:hover {
                        color: white;
                        background: transparent;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        span.toggler {
          position: absolute;
          top: 0;
          background: $primary-color;
          color: #ffffff;
          left: -40px;
          display: inline-flex;
          text-align: center;
          align-items: center;
          height: 25px;
          width: 25px;
          border-radius: 50%;

          &:before {
            content: "\f067";
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            margin: auto;
          }

          &.open {
            &:before {
              content: "\f068";
              font-family: 'Font Awesome 5 Free';
              font-weight: 900;
            }
          }
          @include medium-up {
            font-size: 10px;
            left: auto;
            top: 10px;
            right: -15px;
            width: 20px;
            height: 20px;
          }
        }

        a {
          display: block;
          padding: 0;
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 500;
          font-style: normal;
          font-size: 20px;
          font-family: $primary-font;
          color: $header-background-color;
          @include medium-up {
            color: $color01;
            font-size: 18px;
            border: none;
            margin: 10px;
            font-weight: $bold;
            letter-spacing: 1px;
            padding: 0 0 10px;
          }

          &:hover {
            text-decoration: none;
            cursor: pointer;
            color: $color02;
          }
        }
      }

      //Dropdown Level 1
      &.ul2 {
        display: none;
        padding: 25px 0;
        background: transparent;
        white-space: nowrap;
        height: auto;
        position: relative;
        top: 0;
        padding: 0;
        transform: none;
        min-height: auto;
        @include medium-up {
          position: absolute;
          top: 43px;
          right: auto;
          background: $desktop-subnav-flyout-color;
          padding: 25px 0;
          left: 10px;
          white-space: normal;
          width: 250px;
        }

        li {
          margin: 20px 0 35px;
          @include medium-up {
            display: block;
            clear: both;
            width: 100%;
            margin: 0;
          }

          a {
            font-size: 16px;
            border-bottom: none;
            text-transform: none;
            color: $mobile-subnav-color;
            @include medium-up {
              color: white;
              border-bottom: none;
              padding: 10px 25px;
              text-transform: none;
              font-weight: 600;
              font-style: normal;
              font-size: 14px;
              font-family: $primary-font;
              color: white;
              margin: 0;

              &:hover {
                background: $desktop-subnav-flyout-item-hover-color;
              }
            }
          }
        }
      }
    }
  }
}