.branding {
  position: relative;
  display: block;
  width: 100%;
  padding: 20px;
  background: $header-background-color;
  @include medium-up {
    position: relative;
    top: 0;
    width: 200px;
    height: 50px;
    padding: 0;
  }

  a {
    transform: none;
    width: 230px;
    height: 90px;
    position: relative;
    margin: auto;
    @include medium-up {
      position: absolute;
      top: 50%;
      z-index: 1;
      display: inline-block;
      transform: translateY(-50%);
    }

    .logo {
      background-position: center;
      background: url(/themes/athena/assets/dist/images/logo.png) no-repeat;
      background-size: contain;
      width: 100%;
      height: 90px;
      display: block;
      max-width: 230px;
      @include medium-up {
        display: inline-block;
        height: 100%;
      }
    }
  }
}