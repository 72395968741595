// body.authenticated {
//   .alerts {
//     display: inline-flex !important;
//     align-items: center;
//     text-align: left;
//     width: 100%;
//   }
// }

.alerts {
  // display: none;
  background: $color03 !important;

  .alert {
    color: white !important;
    text-decoration: none;
    font-size: 20px;

    &:hover,
    &:focus {
      text-decoration: none !important;

      .alert-text {
        text-decoration: underline !important;
      }
    }

    @media (min-width: 640px) {
      padding: 16px 20px !important;
    }

    &:before {
      content: 'Alert!';
      display: block;
      font-family: $primary-font;
      font-weight: 700;
      font-size: 30px;
      text-transform: uppercase;
      margin-right: 0;

      @media (min-width: 640px) {
        display: inline-block;
        margin-right: 10px;
        font-size: 22px;
      }
    }

    .icon {
      display: none;
    }

    .alert-text {
      text-align: left;
      font-weight: 300;
    }
  }
}